import React from 'react'
import Navbar from '../../layout/Navbar'
import Header from '../../layout/Header'
import Main from './Main'
import ServiceSection from './ServiceSection'
import VideoSection from './VideoSection'
import StatisticSection from './StatisticSection'
import BlogSection from './BlogSection'
import Client from '../../layout/Client'
import Review from '../../layout/Review'
import DownloadSection from '../../layout/downloadSection'
import Footer from '../../layout/Footer'
import { Helmet } from 'react-helmet';
import getSEOData from '../../components/Seo'

function Home() {
  const seo = getSEOData('home');
  return (
    <div className='home'>   
       <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
      </Helmet>  
       <Navbar page='home'></Navbar>
       <Header></Header>
       <Main></Main>
       <ServiceSection></ServiceSection>
       <VideoSection></VideoSection>
       <StatisticSection></StatisticSection>
       <BlogSection></BlogSection>
       <Client></Client>
       <Review></Review>
       <DownloadSection></DownloadSection>
       <Footer></Footer>
    </div>
  )
}

export default Home