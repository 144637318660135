import React, { useEffect, useState } from 'react'
import BlogCard2 from '../../../components/Cards/BlogCard2';
import { useSelector, useDispatch } from 'react-redux'
import { getBlogCards } from '../../../store/Blog/BlogAction';
import Loading from '../../../components/Loading';

const MainBlogs = () => {
    const [numBlogs, setNumBlogs] = useState(9);
    const dispatch = useDispatch();

    const {  blogCards, loading } = useSelector((state) => state.blogCards);

    useEffect(()=>{
        dispatch(getBlogCards({  "blog_count": numBlogs}));
    },[dispatch,numBlogs])

    const increaseNumBlog = ()=>{
        setNumBlogs((prev)=> prev+3);
    }

    return (
        <div className='main-blogs'>
            <div className='primary__heading main-blogs__heading'>Blogs</div>
            {loading?<Loading/>:
              <div className='main-blogs__blogs-container'>
              {blogCards?.data?.map((item, index) => {
                  return <BlogCard2 key={index} data={item} />;
              })}
              </div>
            }
            <button className='main-blogs__button' onClick={increaseNumBlog}>
                Load More
                <svg className='main-blogs__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-down-chevon"></use>
                </svg>
            </button>
        </div>
    )
}

export default MainBlogs