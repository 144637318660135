import React from 'react'
import Navbar from '../../layout/Navbar'
import Header from '../../layout/Header'
import MainAboutUs from './MainAboutUs'
import Team from './Team'
import Client from '../../layout/Client'
import Review from '../../layout/Review'
import DownloadSection from '../../layout/downloadSection'
import Footer from '../../layout/Footer'
import { Helmet } from 'react-helmet';
import getSEOData from '../../components/Seo'

function AboutUs() {
  const seo = getSEOData('about');
  return (
    <div className='about'>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
      </Helmet>
      <Navbar page="about-us"></Navbar>
      <Header></Header>
      <MainAboutUs></MainAboutUs>
      <Team></Team>
      <Client></Client>
      <Review></Review>
      <DownloadSection></DownloadSection>
      <Footer></Footer>
    </div>
  )
}

export default AboutUs