import React, {useEffect} from 'react'
import Navbar from '../../layout/Navbar'
import Header from '../../layout/Header'
import DownloadSection from '../../layout/downloadSection'
import Footer from '../../layout/Footer'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { getBlog } from '../../store/Blog/BlogAction'
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading'
import { getBlogCards } from '../../store/Blog/BlogAction'
import RecentBlogCard from '../../components/Cards/RecentBlogCard'

const BlogPage = () => {
    const dispatch = useDispatch();
    const {  blog, loading } = useSelector((state) => state.blog);
    const { id } = useParams();

    const {blogCards} = useSelector((state) => state.blogCards);

    useEffect(()=>{
        dispatch(getBlogCards({  "blog_count": 4}));
    },[dispatch])


    useEffect(()=>{
        dispatch(getBlog({  "blog_id": id}));

    },[dispatch,id])


  return (
    <div className='blogPage'>
      <Helmet>
        <title>{blog?.data?.title}</title>
        <meta name="description" content={blog?.data?.meta_description} />
        <meta name="keywords" content={blog?.data?.meta_keywords} />
      </Helmet>      
       <Navbar></Navbar>
       <Header></Header>
       {loading?<Loading/>:<div className='blogPage__main'>
         <div className='blogPage__box--left' dangerouslySetInnerHTML={{ __html: blog?.data?.description }} />
         <div className='blogPage__box--right'>
          <div className='blogPage__recent-heading'>Recent Posts:</div>
         {blogCards?.data?.map((item, index) => {
                  return <RecentBlogCard key={index} card={item} />;
              })}
         </div>
       </div>}
       <DownloadSection></DownloadSection>
       <Footer></Footer>
    </div>
  )
}

export default BlogPage