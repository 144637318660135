import React from 'react'
import Navbar from '../../layout/Navbar'
import Header from '../../layout/Header'
import MainBusiness from './MainBusiness'
import Client from '../../layout/Client'
import Review from '../../layout/Review'
import DownloadSection from '../../layout/downloadSection'
import Footer from '../../layout/Footer'
import { Helmet } from 'react-helmet';
import getSEOData from '../../components/Seo'

function Business() {
  const seo = getSEOData('business');
  return (
    <div className='business'>   
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
      </Helmet>
       <Navbar page="business"></Navbar>
       <Header></Header>
       <MainBusiness></MainBusiness>
       <Client></Client>
       <Review></Review>
       <DownloadSection></DownloadSection>
       <Footer></Footer>
    </div>
  )
}

export default Business