import React from 'react'
import Navbar from '../../layout/Navbar'
import Header from '../../layout/Header'
import MainBlogs from './MainBlogs'
import Review from '../../layout/Review'
import DownloadSection from '../../layout/downloadSection'
import Footer from '../../layout/Footer'
import { Helmet } from 'react-helmet'
import getSEOData from '../../components/Seo'

function Blogs() {
  const seo = getSEOData('blog');
  return (
    <div className='blog'> 
     <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
      </Helmet>      
       <Navbar page="blog"></Navbar>
       <Header></Header>
       <MainBlogs></MainBlogs>
       <Review></Review>
       <DownloadSection></DownloadSection>
       <Footer></Footer>
    </div>
  )
}

export default Blogs