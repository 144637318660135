import React from 'react'
import Navbar from '../../layout/Navbar'
import BookCabHeader from './BookCabHeader'
import BookCabMain from './BookCabMain'
import Review from '../../layout/Review'
import DownloadSection from '../../layout/downloadSection'
import Footer from '../../layout/Footer'

const BookCab = () => {
  return (
    <div>
        <Navbar></Navbar>
        <BookCabHeader></BookCabHeader>
        <BookCabMain></BookCabMain>
        <Review></Review>
        <DownloadSection></DownloadSection>
        <Footer></Footer>
    </div>
  )
}

export default BookCab